import { all, put, call, takeLatest } from 'redux-saga/effects'
import { mongo } from '../../utils/api'
import { actions } from './reducer'
import { message } from 'antd'
import { actions as actionsAuth } from '../Auth/reducer'
import { withLoading } from '../Loading/withSaga'
import BSON from 'bson'
import omit from 'lodash/omit'
import { actionUpdate } from '@stackhouseos/box-core'
import { updateStepData } from '@stackhouseos/flow-client'


function* load() {
  try {
    const collection = mongo().collection('products')
    const results = yield call([collection, 'find'], {})
    yield put(actions.setItems(results))
  } catch (error) {

  }
}

function* getById({ payload }) {
  const collection = mongo().collection('products')
  const result = yield call([collection, 'findOne'], {
    _id: new BSON.ObjectId(payload.id.toString())
  })
  yield put(actions.setEdited(result))
}

function* destroy() {
  yield put(actionUpdate('products', 'data', {}))
}

function* save({ payload }) {
  const { flowName, reducerName } = payload
  const collection = mongo().collection('products')
  const data = omit(payload.value, ['_id'])

  try {
    const result = yield call(
      [collection, 'updateOne'],
      { _id: new BSON.ObjectId(payload.value._id.toString()) },
      { $set: data }
    )
    if (!result.modifiedCount) {
      message.success('Nessun prodotto modificato')
    }
    message.success('Prodotto modificato')
    yield put(updateStepData(flowName, 'success', true))
  } catch (error) {
    console.log('error:', error)
    yield put(actionUpdate('flow', 'saga.error', error.message))
  } finally {
    if (flowName)
      yield put({ type: 'flow/next', payload: { flowName, reducerName } })
  }
}

function* saveNew({ payload }) {
  const { flowName, reducerName, value } = payload
  const collection = mongo().collection('products')

  try {
    yield call([collection, 'insertOne'], value)
    message.success('Prodotto Aggiunto')
    yield put(updateStepData(flowName, 'success', true))
  } catch (error) {
    console.log('error', error)
    yield put(actionUpdate('flow', 'saga.error', error.message))
  } finally {
    if (flowName)
      yield put({ type: 'flow/next', payload: { flowName, reducerName } })
  }
}

export default function* appSaga() {
  yield all([
    yield takeLatest(
      [actionsAuth.setLoginData.toString(), 'LOAD_PRODUCTS'],
      withLoading('publisherList', load)
    ),
    yield takeLatest('LOAD_PRODUCT', withLoading('productDetail', getById)),
    yield takeLatest('SAVE_PRODUCT', withLoading('productDetail', save)),
    yield takeLatest('SAVE_PRODUCT_NEW', withLoading('productDetail', saveNew)),
    yield takeLatest(
      ({ type, payload }) =>
        type === 'flow/destroy' && payload.name === 'editProduct',
      destroy
    )
  ])
}
