import { createSlice } from '@reduxjs/toolkit'
import _set from 'lodash/set'
import { withPaginationReducer } from '../Pagination/withReducer'

export const initialState = {
  loading: false,
  items: [
    {
      key: '1',
      name: `Contatto 1`
    },
    {
      key: '2',
      name: `Contatto 2`
    },
    {
      key: '3',
      name: `Contatto 3 `
    }
  ]
}

const slice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setEdited: (state, action) => {
      state.edited = action.payload
    },
    setItems: (state, { payload }) => {
      state.items = payload
    },
    addItems: (state, { payload }) => {
      state.items.push(payload)
    },
    clearEdited: (state) => {
      state.edited = undefined
    }
  },
  extraReducers: {
    '@box/contacts/update': (state, action) =>
      _set(state, action.payload.id, action.payload.value)
  }
})

export const actions = slice.actions
slice.reducer = withPaginationReducer(slice.reducer, slice.name, initialState)
export default slice
