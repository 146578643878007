import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams, useLocation } from '@reach/router'
import { split, get, compact } from 'lodash'
import { getData, getAllRoutes } from './selectors'

const BreadcrumbItem = ({ path, name, last, i }) => {
  const params = useParams()
  const location = useLocation()
  const routes = useSelector(getAllRoutes)

  const paths = split(location.pathname, '/')
  const parentRoute = get(paths, [paths.indexOf(path) - 1])
  const parentParam = get(routes, parentRoute, {})
  const data = useSelector(getData(parentParam.reducer, parentParam.valuePath, get(params, parentParam.param)))
  const url = '/' + compact(paths.slice(0, paths.indexOf(path) + 1)).join('/')

  return last === i
    ? <span >{(data || name || '').slice(0, 40)}...</span>
    : <Link to={url}>{data || name}</Link>
}

export default React.memo(BreadcrumbItem)
