import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  feeds: true
}

const slice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state[payload.key] = payload.loading
    }
  }
})

export const actions = slice.actions
export default slice
