"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledTransfer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _antd = require("antd");

var _templateObject;

var StyledTransfer = (0, _styledComponents["default"])(_antd.Transfer)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  .ant-transfer-list {\n    border-radius: 20px;\n  }\n  .ant-transfer-list-header {\n   border-color: #115191;\n   border-top-left-radius: 20px;\n   border-top-right-radius: 20px;\n  }\n  .ant-btn-sm ant-btn-icon-only {\n    background: #115191\n  }\n  .ant-btn-primary {\n    background: #115191;\n    border-color: #115191; \n    color: #fff;\n  }\n  .ant-btn-primary[disabled] {\n    background: #fff !important;\n    border-color: #fff !important; \n    color: rgba(0, 0, 0, 0.25) !important;\n  },\n"])));
exports.StyledTransfer = StyledTransfer;