export const DASHBOARD = '/'
export const REGISTRATION = '/registration'
export const CONFIRM = '/confirm'
export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const RECOVERYPASSWORD = '/recovery-password'
export const RECOVERYPASSWORDCONFIRM = '/password-confirm'
export const DEACTIVED = '/402'
export const FORBIDDEN = '/403'
export const STATUS_VALID = 'active'
export const CHANGEPASSWORD = '/change-password' 
export const ACCOUNT = '/account'
export const ORDER_PENDING = '/order-pending'
export const ORDER_SUCCESS = '/order-success'
export const ORDER_CANCEL = '/order-cancel'

export const roles = {
  MASTER: 'master',
  USER: 'user',
  PUBLISHER: 'publisher',
  ADMIN: 'admin'
}
