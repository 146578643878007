import { call, all, put, takeEvery, spawn, takeLatest } from 'redux-saga/effects'
import * as Realm from "realm-web";
import { navigate } from '@reach/router'
import { message } from 'antd';
import { app } from '../../utils/api'
import { withLoading } from '../Loading/withSaga'
import { actions } from './reducer'
import { LOGIN, RESETPASSWORD, RECOVERY, RECOVERYCONFIRM, LOGOUT, REFRESH } from './actions'
import { LOGIN as LOGINROUTE, DASHBOARD, REGISTRATION } from './constants'
import { updateStepData } from '@stackhouseos/flow-client';

const disableRegistration = process.env.REACT_APP_AUTH_DISABLE_REGISTRATION

async function realmLogin(email, password) {

  if (!app.currentUser) {
    // If no user is logged in, log in an anonymous user
    await app.logIn(Realm.Credentials.emailPassword(email.toLowerCase(), password));
  } else {
    // The logged in user's access token might be stale,
    // Refreshing custom data also refreshes the access token
    await app.currentUser.refreshCustomData();
  }

  return app.currentUser;
}

function* handleLogin({ payload }) {
  const { flowName, reducerName, value } = payload
  const { email, password } = value

  try {
    yield window.localStorage.clear()

    yield app.logIn(Realm.Credentials.emailPassword(email.toLowerCase(), password.toString()));

    yield put(actions.setLoginData({
      isLoggedIn: true,
    }))

    yield put(updateStepData('app', 'success', true))

  } catch (error) {
    console.log("-> error", error)
    yield put(updateStepData('app', 'error', error.message))
  } finally {
    yield put({ type: 'flow/next', payload: { flowName, reducerName } })
  }
}



function* handleRecovery({ payload }) {
  const { username, password, flowName, reducerName, } = payload
  try {
    yield app.emailPasswordAuth.callResetPasswordFunction(username.toLowerCase(), password);
    message.success('Ti abbiamo inviato una mail per il recupero della password', 10);
    yield put(updateStepData(flowName, 'success', true))
  } catch (err) {
    console.log(err)
    yield put(updateStepData(flowName, 'error', err))
  } finally {
    yield put({ type: 'flow/next', payload: { flowName, reducerName } })
  }

}

function* handleRecoveryConfirm({ payload }) {
  const { token, tokenId, newPassword } = payload
  try {
    yield app.emailPasswordAuth.resetPassword(token, tokenId, newPassword);
    yield spawn(navigate, DASHBOARD)

  } catch (error) {
    yield put(actions.setError({ message: 'Error password' }))
  }
}

function* handleLogout({payload}) {
  console.log('LOGOUT')
  const { flowName, reducerName } = payload
  try {
    if (app.currentUser) {
      yield app.currentUser.logOut()
    }
    yield put(actions.resetLoginData())
  } catch (error) {
    console.log(error)
  } finally {
    yield window.localStorage.clear()
    window.location.href = '/'
  }

}

function* handleRefresh() {
  try {
    if (app.currentUser) {
      const customData = yield app.currentUser.refreshCustomData();

      if (!disableRegistration && (customData.status === undefined || customData.status === 'none')) {
        yield spawn(navigate, REGISTRATION)
        return
      }

      yield put(actions.setLoginData({
        key: customData.key,
        role: customData.role,
        status: customData.status,
        Products: customData.Products || [],
        keywords: customData.keywords || [],
      }))
    }
  } catch (error) {
  }
}


function* handleResetPassword({ payload }) {
  const { password } = payload
  //TODO
}

export default function* rootSaga() {
  yield all([
    takeEvery(LOGIN, handleLogin),
    takeEvery(REFRESH, handleRefresh),
    takeEvery(LOGOUT, handleLogout),
    takeEvery(RECOVERY, handleRecovery),
    takeEvery(RECOVERYCONFIRM, handleRecoveryConfirm),
    takeLatest(RESETPASSWORD, handleResetPassword),
  ])
}
