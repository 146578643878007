import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const Reset = React.memo(({ flowName }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: 'flow/reset', payload: { name: flowName } })
  }, [dispatch, flowName])

  return null
})

export default Reset