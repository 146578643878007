import styled from 'styled-components'
import { media } from 'unioneofferte-core/src/utils/theme'

export const PageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.primary};
`

export const PageContent = styled.div`
  position: relative;
  flex: 1;
`

export const BurgerMenu = styled.div`
  position: absolute;
  font-size: 30px;
  top: 10px;
  right: 30px;
`
