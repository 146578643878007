import Box from '@stackhouseos/box-core'
import Reset from '../components/Reset'
import Navigate from '../components/Navigate'
import FormInput from 'unioneofferte-core/lib/components/Input/FormInput'
import FormButton from 'unioneofferte-core/lib/components/Button/FormButton'
import FormSelect from 'unioneofferte-core/lib/components/Select/FormSelect'
import EditableList from 'unioneofferte-core/lib/components/EditableList'
import ResultOperation from 'unioneofferte-core/lib/components/Result'
import Row from 'unioneofferte-core/lib/components/Row'
import Spacer from 'unioneofferte-core/lib/components/Spacer'
import Switch from 'unioneofferte-core/lib/components/Switch'
import DateRange from 'unioneofferte-core/lib/components/DateRange'
import DatePicker from 'unioneofferte-core/lib/components/DatePicker'
import Text from 'unioneofferte-core/lib/components/Text'
import Button from 'unioneofferte-core/lib/components/Button'
import Stepper from 'unioneofferte-core/lib/components/Stepper'
import LogoFull from 'unioneofferte-core/lib/components/LogoFull'
//import Input from 'unioneofferte-core/lib/components/Input'
import Dropzone from 'unioneofferte-core/lib/components/Dropzone'
import DynamicForm from 'unioneofferte-core/lib/components/DynamicForm'
import Select from 'unioneofferte-core/lib/components/Select'
import Transfer from 'unioneofferte-core/lib/components/Transfer'
import Table from 'unioneofferte-core/lib/components/Table'
import HeaderPreview from 'unioneofferte-core/lib/components/Preview/Header'
import ListOffers from 'unioneofferte-core/lib/components/Preview/ListOffers'
import PageRightSidebar from 'unioneofferte-core/lib/components/Layout/PageRightSidebar'
import Statistic from 'unioneofferte-core/lib/components/Statistic'
import FormControlWrapper from 'unioneofferte-core/lib/components/FormControlWrapper'
import FormTextArea from 'unioneofferte-core/lib/components/Input/FormTextArea'
import Checkbox from 'unioneofferte-core/lib/components/Checkbox'
import Col from 'unioneofferte-core/lib/components/Col'
import PageHeader from '../components/PageHeader'
import UploadFile from '../components/UploadFile'
import Saga from 'unioneofferte-core/lib/components/Saga'
import Delay from '../components/Delay'
import Dashboard from '../components/Dashboard'
import Page from '../components/Page'
import Subflow from '../components/Subflow'
import SlugUrl from '../components/SlugUrl'
import Router from '../components/Router'
import FlexContainer from '../components/FlexContainer'
import PopulatedData from '../components/PopulatedData'
import IFrame from '../components/IFrame'
import PreviewImage from '../components/PreviewImage'
import OrderProductApiStatus from '../components/OrderProductApiStatus'

Box.extendControls({
  IFrame,
  Dashboard,
  Page,
  Subflow,
  Router,
  Row,
  Col,
  ResultOperation,
  FormInput,
  FormButton,
  FormSelect,
  FlexContainer,
  SlugUrl,
  Statistic,
  EditableList,
  LogoFull,
  Spacer,
  Switch,
  DateRange,
  DatePicker,
  Checkbox,
  FormControlWrapper,
  PopulatedData,
  Text,
  Button,
  Stepper,
  PreviewImage,
  //Input,
  Dropzone,
  DynamicForm,
  Select,
  Transfer,
  Table,
  Reset,
  Navigate,
  HeaderPreview,
  ListOffers,
  PageRightSidebar,
  PageHeader,
  Saga,
  FormTextArea,
  Delay,
  UploadFile,
  OrderProductApiStatus
})
