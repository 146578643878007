import { all, put, call, spawn, select, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'
import { actionUpdate } from '@stackhouseos/box-core'
import { message } from 'antd'
import BSON from 'bson'
import { cloneDeep, omit, set } from 'lodash'
import { withLoading } from '../Loading/withSaga'
import app, { mongo } from '../../utils/api'

import { CAMPAIGN_DETAIL, CAMPAIGN_SEND } from '../App/routes'
import { actions } from './reducer'
import { getCampaignData, getCampaignEmail, getCampaignSlug } from './selectors'
import get from 'lodash/get'
import { composePath } from 'unioneofferte-core/lib/utils/helpers'
import { updateStepData } from '@stackhouseos/flow-client'

function* load() {
  const collection = mongo().collection('campaigns')
  const results = yield collection.find({}, { sort: { createdAt: -1 } })
  yield put(actions.setItems(results))
}

function* loadCampaign({ payload }) {
  const { flowName, reducerName } = payload || {}
  const data = yield select(getCampaignData)
  const collection = mongo().collection('campaigns')

  yield put(actionUpdate('flow', 'saga.success', undefined))
  yield put(actionUpdate('flow', 'error.message', undefined))

  try {
    const _id = get(data, '_id')

    if (!_id) {
      throw new Error('no-id')
    }

    const result = yield call([collection, 'findOne'], {
      _id: new BSON.ObjectId(_id.toString())
    })

    yield put(
      actionUpdate('campaigns', 'data', {
        ...result,
        campaignId: _id === 'create' ? new BSON.ObjectId().toString() : _id
      })
    )
    yield put(actionUpdate('flow', 'saga.success', true))
  } catch (error) {
    yield put(actionUpdate('flow', 'saga.error', error.message))
  } finally {
    if (flowName)
      yield put({ type: 'flow/next', payload: { flowName, reducerName } })
  }
}

function* updateCampaign({ payload }) {
  const { flowName, reducerName } = payload

  const collection = mongo().collection('campaigns')

  const data = cloneDeep(omit(payload.value, ['_id', 'step']))

  try {
    /* const prices = data.prices || []
    var i = 0
    for (const offer of prices) {
      const methodPrice = offer.stripeId ? 'updatePrice' : 'createPrice'
      const price = yield app.currentUser.functions[methodPrice](
        data.stripeId,
        offer.title,
        offer.price,
        offer.products.map((e) => e.key).join(','),
        offer.stripeId,
      )
      set(data.prices, [i, 'stripeId'], price.id)
      i++
    } */

    const result = yield call(
      [collection, 'updateOne'],
      { _id: new BSON.ObjectId(payload.value._id.toString()) },
      {
        $set: {
          ...data,
          validAt: {
            start: new Date(data.validAt.start),
            end: new Date(data.validAt.end)
          }
        }
      }
    )

    if (!result.modifiedCount) {
      alert('Errore, riprova')
    }

    message.success('Campagna modificata')

    yield put(updateStepData(flowName, 'success', true))
  } catch (error) {
    console.log('-> error', error)
    yield put(updateStepData(flowName, 'error', error.message))

    message.success(`${error}`)
  } finally {
    yield put({ type: 'flow/next', payload: { flowName, reducerName } })
  }
}

function* saveNew({ payload }) {
  const { flowName, reducerName, value } = payload

  const data = cloneDeep(omit(value, ['_id', 'step']))
  const collection = mongo().collection('campaigns')

  try {
    // const newId = new BSON.ObjectId()
    // const nameProduct = data.slug

    // add product stripe
    /*     const product = yield app.currentUser.functions.createProduct(
          newId.toString(),
          nameProduct,
          data.description
        ) */

    // add prices
    // TODO al momento usiamo i prodotti temporanei su stripe
    /*  
    const prices = data.prices || []
    var i = 0
    for (const offer of prices) {
      const price = yield app.currentUser.functions.createPrice(
        product.id,
        offer.title,
        offer.price,
        offer.products.map((e) => e.key).join(',')
      )
      set(data.prices, [i, 'stripeId'], price.id)
      i++
    } 
    */

    // add campaign id
    yield call([collection, 'insertOne'], {
      ...data,
      validAt: {
        start: new Date(data.validAt.start),
        end: new Date(data.validAt.end)
      }
    })

    message.success('Campagna aggiunta')

    yield put(updateStepData(flowName, 'success', true))
  } catch (error) {
    yield put(updateStepData(flowName, 'error', error.message))
    message.success(`${error}`)
  } finally {
    yield put({ type: 'flow/next', payload: { flowName, reducerName } })
  }
}

function* destroy() {
  yield put(actionUpdate('campaigns', 'data', {}))
}

function* openSendCampaign({ payload }) {
  yield put(actionUpdate('campaigns', 'data', payload))
  const path = composePath(`/${CAMPAIGN_SEND}`, { id: payload._id })
  yield spawn(navigate, path)
}

function* sendCampaign({ payload }) {
  const { flowName, reducerName } = payload

  const dataEmail = yield select(getCampaignEmail)
  const campaign = yield select(getCampaignData)
  const slug = yield select(getCampaignSlug)

  // USARE ENV
  const link = `https://${process.env.REACT_APP_DOMAIN}/${slug}`
  const { email, body } = dataEmail

  try {
    yield app.currentUser.functions.sendCampaign(campaign._id.toString(), email, { message: body, link })
    yield put(updateStepData(flowName, 'success', true))

  } catch (error) {
    yield put(updateStepData(flowName, 'error', error.message))
  } finally {
    yield put({ type: 'flow/next', payload: { flowName, reducerName } })
  }
}

function* cloneCampaign({ payload }) {
  const { flowName, reducerName } = payload

  const data = yield select(getCampaignData)
  const collection = mongo().collection('campaigns')

  try {

    yield call([collection, 'insertOne'], { ...omit(data, ['_id', 'id']), active: false, slug: '' })
    message.success('Campagna duplicata')
    yield put(updateStepData(flowName, 'success', true))

  } catch (error) {
    yield put(updateStepData(flowName, 'error', error.message))
  } finally {
    yield put({ type: 'flow/next', payload: { flowName, reducerName } })
  }
}

function* removeCampaign({ payload }) {
  const collection = mongo().collection('campaigns')

  // todo check se è già stata inviata
  // TODO !!!! non far cancellare le campagne per ora

  try {
    const result = yield call([collection, 'deleteOne'], {
      _id: new BSON.ObjectId(payload._id.toString())
    })

    if (!result.deletedCount) {
      message.success('Nessuna campagna modificata')
    }

    if (result.deletedCount) {
      message.success('Campagna eliminata')
    }
  } catch (error) {
    console.log(error)
  }
}

export default function* appSaga() {
  yield all([
    yield takeLatest('LOAD_CAMPAIGNS', withLoading('campaignList', load)),
    yield takeLatest(
      'LOAD_CAMPAIGN',
      withLoading('campaignDetail', loadCampaign)
    ),
    yield takeLatest('UPDATE_CAMPAIGN', updateCampaign),
    yield takeLatest('SAVE_NEW_CAMPAIGN', saveNew),
    yield takeLatest('REMOVE_CAMPAIGN', removeCampaign),
    yield takeLatest('SEND_CAMPAIGN', sendCampaign),
    yield takeLatest('CLONE_CAMPAIGN', cloneCampaign),
    yield takeLatest(
      'OPEN_SEND_CAMPAIGN',
      withLoading('campaignDetail', openSendCampaign)
    ),
    yield takeLatest(
      ({ type, payload }) =>
        type === 'flow/destroy' && payload.name === 'editCampaign',
      destroy
    )
  ])
}
