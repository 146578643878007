"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InfoPrice = exports.ItemContainer = exports.ListItemContainer = exports.Main = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _theme = require("../../../../lib/utils/theme");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

var Main = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  background-color: #252b42;\n  align-items: center;\n  padding: 60px;\n  flex-direction: column;\n  .main-description {\n    margin-top: 20px;\n    margin-bottom: 2em;\n  }\n"])));

exports.Main = Main;

var ListItemContainer = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: row;\n  ", "\n  .middle {\n    background-color: #1890ff;\n  }\n"])), _theme.media.maxWidth('md')(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  flex-direction: column;\n"]))));

exports.ListItemContainer = ListItemContainer;

var ItemContainer = _styledComponents["default"].div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  padding: 40px;\n  flex: 1;\n  margin: 0 25px;\n  border-radius: 10px;\n  background-color: #fff;\n  flex-direction: column;\n  max-width: 500px;\n  \n  ", "\n\n  .options {\n    display: flex;\n    margin: 10px 0 0 30px;\n    justify-content: flex-start;\n    align-items:center;\n\n    .anticon{\n      margin-right: 10px;\n    }\n\n  }\n\n  > a {\n    text-align:center;\n    margin-top: 30px;\n  }\n\n  .flex-spacer{\n    flex:1;\n  }\n"])), _theme.media.maxWidth('md')(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2["default"])(["\n    max-width: 435px;\n    margin: 2em 0;\n    width: 100%;\n  "]))));

exports.ItemContainer = ItemContainer;

var InfoPrice = _styledComponents["default"].div(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  .description {\n    display: flex;\n  }\n  .price {\n    display: flex;\n    align-self: center;\n    align-items: center;\n    flex-direction: row;\n    > div {\n      display: flex;\n      margin-left: 10px;\n      flex-direction: column;\n    }\n  }\n"])));

exports.InfoPrice = InfoPrice;