import React, { Suspense, useEffect } from 'react'
import { Router } from '@reach/router'

import GlobalStyle from 'unioneofferte-core/lib/utils/GlobalStyle'
import Loading from 'unioneofferte-core/lib/components/Loading'

import './utils/initBox'
import Flow from '@stackhouseos/flow-client/lib/components/Flow'
import flow from './app.flow.json'
import { useDispatch } from 'react-redux'
import app from './utils/api'

const App = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    if (app.currentUser) {
      dispatch({ type: 'INIT_APP', payload: {} })
    }
  }, [dispatch])

  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Flow
          name={flow.name}
          elements={flow.elements}
          reducerName={'app'}
          path={'/*'}
        />
      </Router>
      <GlobalStyle />
    </Suspense>
  )
}

export default App
