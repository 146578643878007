import React from 'react'
import { useSelector } from 'react-redux'
import { useWindowWidth } from '@react-hook/window-size'
import Text from 'unioneofferte-core/lib/components/Text'
import { breakpoints } from 'unioneofferte-core/lib/utils/theme'
import { PageHeaderContainer, MainRow, HeaderLeftComponent, HeaderRightComponent } from './styled'
import Breadcrumbs from '../Breadcrumbs'


const PageHeader = ({ title, subtitle, right_container, accountName }) => {
  const width = useWindowWidth()
  const isScreenMedium = width > breakpoints.sm

  return (
    <PageHeaderContainer>
     {/*  <Breadcrumbs /> */}
      <MainRow>
        <HeaderLeftComponent>
          <Text type='primary' block size={32} lineHeight={1}>
            {title}
          </Text>
          {subtitle && <Text type='textLight'>{subtitle}</Text>}
        </HeaderLeftComponent>
        {isScreenMedium && right_container && (
          <HeaderRightComponent>
            {right_container}
          </HeaderRightComponent>
        )}
      </MainRow>

      {!isScreenMedium && right_container && (
        <HeaderRightComponent>
          {right_container}
        </HeaderRightComponent>
      )}

    </PageHeaderContainer>
  )
}

export default React.memo(PageHeader)
