import styled from 'styled-components'

export const BreadcrumbsWrapper = styled.div`
  padding: 20px 0;
  .ant-breadcrumb {
    > span:last-child {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`
