import { createSlice } from '@reduxjs/toolkit'
import _set from 'lodash/set'
import { withPaginationReducer } from '../Pagination/withReducer'

export const initialState = {
  loading: false,
  items: []
}

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setEdited: (state, action) => {
      state.edited = action.payload
    },
    setItems: (state, { payload }) => {
      state.items = payload
    },
    addItems: (state, { payload }) => {
      state.items.push(payload)
    },
    clearEdited: (state) => {
      state.edited = undefined
    },
    
  },
  extraReducers: {
    '@box/products/update': (state, action) => _set(state, action.payload.id, action.payload.value),
  },
})

export const actions = slice.actions
slice.reducer = withPaginationReducer(slice.reducer, slice.name, initialState)
export default slice
