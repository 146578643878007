import React from 'react'
import { StyledContainer } from './styled'

const FlexContainer = ({children}) => {
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  )
}
export default React.memo(FlexContainer)
