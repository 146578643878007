import { createAction } from '@reduxjs/toolkit'

export const LOGIN = 'auth/LOGIN'
export const RESETPASSWORD = 'auth/RESETPASSWORD'
export const ANONYMOUS = 'auth/ANONYMOUS'
export const SIGNUP = 'auth/SIGNUP'
export const RECOVERY = 'auth/RECOVERY'
export const RECOVERYCONFIRM = 'auth/RECOVERY-CONFIRM'
export const LOGOUT = 'auth/LOGOUT'
export const ACTIVATE = 'auth/ACTIVATE'
export const REFRESH = 'auth/REFRESH'
export const AUTH_ORDER = 'auth/ORDER'


export const loginActions = {
  login: createAction(LOGIN),
  resetPassword: createAction(RESETPASSWORD),
  loginAnonymous: createAction(ANONYMOUS),
  logout: createAction(LOGOUT),
  recoveryPassword: createAction(RECOVERY),
  recoveryPasswordConfirm: createAction(RECOVERYCONFIRM),
  signup: createAction(SIGNUP),
  activate: createAction(ACTIVATE)
}
