export const HOME = '/'
export const DASHBOARD = '/'

// Dashboard child routes
export const Products = 'products'
export const PRODUCT_EDIT = 'products/:id'
export const PRODUCT_CREATE = 'products/create'
export const Orders = 'orders'
export const ORDER_DETAIL = 'orders/:id'
export const Contacts = 'contacts'
export const CONTACT_EDIT = 'contacts/:id'
export const CAMPAIGNS_MANAGEMENT = 'campaigns'
export const CAMPAIGN_DETAIL = 'campaigns/:id'
export const CAMPAIGN_SEND = 'campaigns/:id/send'
export const CAMPAIGN_CREATE = 'campaigns/create'

export const routesForBreadcrumbs = {
  dashboard: {
    name: 'Unione Offerte'
  },
  products: {
    name: 'Lista Prodotti'
  },
  orders: {
    name: 'Lista Ordini'
  },
  contacts: {
    name: 'Lista Contatti'
  },
  campaigns: {
    name: 'Lista Campagne'
  },
  campaignDetail: {
    name: 'Modifica'
  }
}
