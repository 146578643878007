import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import rootReducer from './reducers'
import createSagaInjector from './sagaInjector'
import saga from './sagas'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['none'],
  stateReconciler: autoMergeLevel2
}

// Connect the sagas to the redux store
const sagaMiddleware = createSagaMiddleware()

const middleware = [
  ...getDefaultMiddleware({
    thunk: false,
    serializableCheck: false
  }),
  sagaMiddleware
]

const persistedReducer = persistReducer(persistConfig, rootReducer)
const preloadedState = {}

const store = configureStore({
  reducer: persistedReducer,
  middleware,
  preloadedState,
  devTools: process.env.NODE_ENV !== 'production'
})

const persistor = persistStore(store)

store.injectSaga = createSagaInjector(sagaMiddleware.run, saga)

export { store, persistor }
