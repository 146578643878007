import React from 'react'
import Text from 'unioneofferte-core/lib/components/Text'

const SlugUrl = ({ value }) => {
  const src = `https://${process.env.REACT_APP_DOMAIN}/${value}`

  return (
    <Text variant='secondary' size='medium'>
      Url: <a href={src}> {src}</a>
    </Text>
  )
}

export default React.memo(SlugUrl)
