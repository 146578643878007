import React from 'react'

const IFrame = React.memo(({ value }) => {
  const src = `https://${process.env.REACT_APP_DOMAIN}/${value}`

  return (
    <iframe title="preview" src={src} style={{ height: '100%', width: '100%', border: 0 }} />
  )
})

export default IFrame