import { Layout, Menu, Breadcrumb } from 'antd';
import React, { useMemo } from 'react';
import { Link, Router } from '@reach/router'
import { PageContent } from '../Page/styled';

const { Header, Content, Footer } = Layout;

const RouteWrapper = ({ component }) => {
  return (
    <PageContent>
      {component}
    </PageContent>
  )
}


const RouterComponent = ({ children }) => {

  const routes = useMemo(() => {
    return React.Children.map(children, (child) => <RouteWrapper key={child.key} default={child.props.field.path === 'default'} path={child.props.field.path} component={child} />)
  }, [children])

  return (
    <Router>
      {routes}
    </Router>
  );
}

export default React.memo(RouterComponent)