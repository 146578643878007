import { all, put, call, takeLatest } from 'redux-saga/effects'
import { mongo } from '../../utils/api'
import { actions } from './reducer'
import { actions as actionsAuth } from '../Auth/reducer'
import { withLoading } from '../Loading/withSaga'
import BSON from 'bson'


function* load() {
  const collection = mongo().collection('orders')
  const results = yield collection.find({}, { sort: { createdAt: -1 }, limit: 1000 })
  yield put(actions.setItems(results))
}

function* getById({ payload }) {
  const collection = mongo().collection('orders')
  const result = yield call([collection, 'findOne'], {
    _id: new BSON.ObjectId(payload.id.toString())
  })
  yield put(actions.setEdited(result))
}


export default function* appSaga() {
  yield all([
    yield takeLatest(
      [actionsAuth.setLoginData.toString(), 'LOAD_ORDERS'],
      withLoading('ordersList', load)
    ),
    yield takeLatest('LOAD_ORDER', withLoading('orderDetail', getById))
  ])
}
