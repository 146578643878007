import { call, put } from 'redux-saga/effects'
import { isFunction } from 'lodash'
import { actions } from './reducer'

export function withLoading(loadingKey, mainSaga, onErrorSaga) {
  return function* handleLoading(action) {
    const key = isFunction(loadingKey) ? loadingKey(action) : loadingKey
    try {
      yield put(actions.setLoading({ key, loading: true }))
      yield call(mainSaga, action)
    } catch (error) {
      if (onErrorSaga) {
        yield call(onErrorSaga, error)
      } else {
        throw new Error(error)
      }
    } finally {
      yield put(actions.setLoading({ key, loading: false }))
    }
  }
}
