import React from 'react'
import { message, Spin } from 'antd';
import axios from 'axios'
import app from '../../utils/api';
import Dropzone from 'unioneofferte-core/lib/components/Dropzone';
import { BSON } from 'realm-web';

export default class UploadFile extends React.PureComponent {

  state = {
    url: null,
    file: null,
    uploading: false,
  };

  componentDidMount() {

    const refId = new BSON.ObjectId().toString()
    this.setState({ refId })

    const { id, mode } = this.props

    const run = async () => {
      // creo un uploadId
      const url = await app.currentUser.functions.getSignedUrlUpload(id, mode, refId)
      this.setState({ url })
    }

    if (refId)
      run()
  }

  handleUpload = (file) => {

    const { url } = this.state
    const { mode, onChange } = this.props
    if (!file) return

    axios
      .put(url, file,
        {
          method: 'put',
          headers: {
            'Content-Type': 'image/' + mode
          },
          onUploadProgress: ({ total, loaded }) => {
            // onProgress({ percent: Math.round(loaded / total * 100).toFixed(2) }, file);
          },
        })
      .then((res) => {
        onChange(this.state.refId);
      }).catch(() => {
        onChange(undefined);
        message.error('Non è stato possibile caricare il file');
      })

    return {
      abort() {
        onChange(undefined);
        console.log('upload progress is aborted.');
      },
    };
  };

  render() {
    const { mode, icon } = this.props
    const { url } = this.state

    return (
      <Spin spinning={!url}>
        {url && <Dropzone
          mode={mode}
          icon={icon}
          url={url}
          onBeforeUpload={this.handleUpload}
        />}
      </Spin>
    );
  }
}