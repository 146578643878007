import React, { useEffect, useContext } from 'react'
import { BoxContext } from '@stackhouseos/box-core';
import Loading from 'unioneofferte-core/lib/components/Loading'
import { useDispatch } from 'react-redux'

const Delay = React.memo(({ duration = 500 }) => {
  const dispatch = useDispatch()
  const { flowName, reducerName } = useContext(BoxContext)

  useEffect(() => {
    const to = setTimeout(() => {
      dispatch({ type: 'flow/next', payload: { flowName, reducerName } })
    }, duration);
    return () => {
      clearTimeout(to)
    }
  }, [dispatch, duration])

  return (
    <div>
      <Loading absolute />
    </div>
  )
})

export default Delay