import { createSelector } from 'reselect'
import _prop from 'lodash/fp/prop'
import _find from 'lodash/find'

const selectState = _prop('app')

export const getAllRoutes = createSelector(selectState, _prop('routes'))

export const getData = (reducer, valuePath, id) => createSelector(
  state => reducer && _prop(reducer)(state),
  (data) => {
    const forceArray = Array.isArray(data) ? data : [data]
    return data && _prop(valuePath)(_find(forceArray, { _id: id }))
  })