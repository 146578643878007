import * as Realm from "realm-web";

export const appId = process.env.REACT_APP_ID;
console.log("TCL: appId", appId)

const appConfig = {
  id: appId,
  timeout: 10000,
};

export const app = new Realm.App(appConfig);
export const mongo = () => app.currentUser.mongoClient("mongodb-atlas").db("main");

export default app