import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { mongo } from '../../utils/api'
import { Tag, Popover } from 'antd';


export default React.memo(function OrderProductApiStatus({ value }) {

  const orderId = useSelector(state => state.orders.data._id.toString())
  const [data, setData] = useState({})
  
  useEffect(() => {
    const load = async () => {
      const orders_products = mongo().collection('orders_products')
      const res = await orders_products.findOne({ refId: `${orderId}__${value}` })
      setData(res)
    }
    if (orderId && value)
      load()
  }, [value, orderId])

  const content = () => {
    return <pre style={{ maxWidth: 400, overflow: 'auto', height: 200 }}>
      {JSON.stringify(data.responses, null, 2)}
    </pre>
  }

  return data ? (
    <div>
      <Popover content={content}>
        <Tag color={data.status === 'error' ? "red" : "green"}>{data.status}</Tag>
      </Popover>
    </div>
  ) : null
})
