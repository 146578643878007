import { all, put, call, takeLatest } from 'redux-saga/effects'
import { mongo } from '../../utils/api'
import { actions } from './reducer'
import { actions as actionsAuth } from '../Auth/reducer'
import { updateStepData } from '@stackhouseos/flow-client'
import { actionUpdate } from '@stackhouseos/box-core'
import { withLoading } from '../Loading/withSaga'
import BSON from 'bson'
import { omit } from 'lodash'
import { message } from 'antd'


function* load() {
  const collection = mongo().collection('contacts')
  const results = yield collection.find({}, { sort: { createdAt: -1 } })
  yield put(actions.setItems(results))
}

function* getById({ payload }) {
  const collection = mongo().collection('contacts')
  const result = yield call([collection, 'findOne'], {
    _id: new BSON.ObjectId(payload.id.toString())
  })
  yield put(actions.setEdited(result))
}

function* save({ payload }) {
  const { flowName, reducerName } = payload
  const collection = mongo().collection('contacts')
  const data = omit(payload.value, ['_id'])

  try {
    const result = yield call(
      [collection, 'updateOne'],
      { _id: new BSON.ObjectId(payload.value._id.toString()) },
      { $set: data }
    )
    if (!result.modifiedCount) {
      message.success('Errore')
    }
    message.success('Salvato')
    yield put(updateStepData(flowName, 'success', true))
  } catch (error) {
    console.log('error:', error)
    yield put(actionUpdate('flow', 'saga.error', error.message))
  } finally {
    if (flowName)
      yield put({ type: 'flow/next', payload: { flowName, reducerName } })
  }
}

export default function* appSaga() {
  yield all([
    yield takeLatest(
      [actionsAuth.setLoginData.toString(), 'LOAD_CONTACTS'],
      withLoading('publisherList', load)
    ),
    yield takeLatest('LOAD_CONTACT', withLoading('contactDetail', getById)),
    yield takeLatest('SAVE_CHANGES_CONTACT', withLoading('contactDetail', save))
  ])
}
