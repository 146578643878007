import styled from 'styled-components'
import { media } from 'unioneofferte-core/lib/utils/theme'

export const PageHeaderContainer = styled.div`
  margin-bottom: 30px;
`

export const MainRow = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 6px;
  margin-bottom: 10px;
`
export const HeaderLeftComponent = styled.div``

export const HeaderRightComponent = styled.div`
  margin-left: auto;
  
  ${media.maxWidth('sm')`
    & {
      margin-left: 0;
      margin-top: 20px;
    }
  `}
`
