import { createSlice } from '@reduxjs/toolkit'
import app from '../../utils/api'
import _unset from 'lodash/unset'
import _set from 'lodash/set'
import _merge from 'lodash/merge'

const initialState = {
  isLoggedIn: false,
  role: null,
  Products: [],
  keywords: [],
  subscription: null,
  plan: null
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      state.error = payload.message
    },
    clearError: (state) => {
      _unset(state, 'error')
    },
    setRole: (state, action) => {
      state.role = action.payload
    },
    setLoginData: (state, { payload }) => {
      _merge(state, payload)
    },
    setKeywords: (state, { payload }) => {
      state.keywords = payload.keywords
    },
    setProducts: (state, { payload }) => {
      state.Products = payload.Products
    },
    resetLoginData: () => initialState
  },
  extraReducers: {
    '@box/auth/update': (state, action) => _set(state, action.payload.id, action.payload.value),
  },
})

export const actions = slice.actions
export default slice
