import { createSlice } from '@reduxjs/toolkit'
import { set } from 'lodash'
import { withPaginationReducer } from '../Pagination/withReducer'

export const initialState = {
  loading: false,
  new: {
    offers: []
  }
}

const slice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setEditedCampaign: (state, action) => {
      state.edited = action.payload
    },
    setItems: (state, { payload }) => {
      state.items = payload
    },
    clearEdited: (state) => {
      state.edited = undefined
    },
    // setOffer: (state, { payload }) => {
    //   state.new.offers.push(payload)
    // },
    // clearTemp: (state) => {
    //   state.temp.offer.title = ''
    //   state.temp.offer.price = ''
    //   state.temp.offer.description = ''
    // },
    clearAll: (state) => {
      state.data = undefined
    },
    deleteOffer: (state, { payload }) => {
      //TODO with ID
      state.data.prices = state.data.prices.filter(e => e._id !== payload._id)
    },

  },
  extraReducers: {
    '@box/campaigns/update': (state, action) => set(state, action.payload.id, action.payload.value)
  },
})

export const actions = slice.actions
slice.reducer = withPaginationReducer(slice.reducer, slice.name, initialState)
export default slice
