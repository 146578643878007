import React, { useMemo } from 'react'
import { Breadcrumb } from 'antd'
import { useLocation } from '@reach/router'
import { split, map, compact } from 'lodash'
import { BreadcrumbsWrapper } from './styled'
import BreadcrumbItem from './item'

const Breadcrumbs = () => {
  const location = useLocation()
  const paths = split(location.pathname, '/')
  const cleanedPaths = compact(paths)
  const last = cleanedPaths.length

  const items = useMemo(() => ([
    {
      key: '/',
      path: '/',
      name: 'Home',
      breadcrumbName: 'Home',
      i: -1
    },
    ...map(cleanedPaths, (path, i) => {
      return {
        key: path + i,
        path,
        name: path,
        breadcrumbName: path,
        last,
        i
      }
    })
  ]), [cleanedPaths, last])

  const itemRender = (route) => <BreadcrumbItem {...route} />

  if (cleanedPaths.length === 0) return null

  return (
    <BreadcrumbsWrapper>
      <Breadcrumb routes={items} itemRender={itemRender} />
    </BreadcrumbsWrapper>
  )
}

export default React.memo(Breadcrumbs)
