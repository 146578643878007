import { createAction } from '@reduxjs/toolkit'

export const SET_PAGE = 'SET_PAGE'
export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_PAGINATION_PROPS = 'SET_PAGINATION_PROPS'
export const RESET_PAGINATION = 'RESET_PAGINATION'

export const getPaginationActions = reducer => ({
  setPage: createAction(`${reducer}/${SET_PAGE}`, prepareAction(reducer)),
  setPagination: createAction(`${reducer}/${SET_PAGINATION}`, prepareAction(reducer)),
  setPaginationProps: createAction(`${reducer}/${SET_PAGINATION_PROPS}`, prepareAction(reducer)),
  resetPagination: createAction(`${reducer}/${RESET_PAGINATION}`, prepareAction(reducer))
})

export const feedPaginationActions = getPaginationActions('feeds')
export const usersPaginationActions = getPaginationActions('campaigns')
export const uploadsPaginationActions = getPaginationActions('uploads')
export const ProductsPaginationActions = getPaginationActions('Products')

function prepareAction(reducer) {
  return content => ({
    payload: content,
    meta: { reducer }
  })
}
