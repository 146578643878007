"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledCard = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _antd = require("antd");

var _templateObject;

var StyledCard = (0, _styledComponents["default"])(_antd.Card)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  border: none;\n  .ant-card-body {\n    padding: 15px;\n    display: flex;\n    flex-direction: column;\n  }\n"])));
exports.StyledCard = StyledCard;