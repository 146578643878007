import React from 'react'
import { PageContent } from './styled'

const Page = (props) => {
  const { children } = props
  return (
      <PageContent>
        {children}
      </PageContent>
  )
}

export default React.memo(Page)
