import { combineReducers } from '@reduxjs/toolkit'
import auth from '../containers/Auth/reducer'
import loading from '../containers/Loading/reducer'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { persistReducer } from 'redux-persist'
import { flowReducer } from '@stackhouseos/flow-client'
import storage from 'redux-persist/lib/storage'

const context = require.context('../', true, /containers\/[\s\S]*?\/reducer.js$/)
const reducers = context.keys().map(filename => context(filename).default)

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  whitelist: ['isLoggedIn'],
  stateReconciler: autoMergeLevel2
}

export default combineReducers({
  loading: loading.reducer,  
  flow: flowReducer,
  ...reducers.reduce((acc, inc) => ({ ...acc, [inc.name]: inc.reducer }), {}),
  auth: persistReducer(authPersistConfig, auth.reducer),
})
