"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaginationContainer = exports.TableContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject, _templateObject2;

var TableContainer = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  .table-rows-clickable {\n    table {\n      tbody {\n        tr {\n          cursor: pointer;\n        }\n      }\n    }\n  }\n  .ant-table {\n    ", "\n  }\n"])), function (_ref) {
  var minWidth = _ref.minWidth;
  return minWidth && "min-width: ".concat(minWidth, "px;");
});

exports.TableContainer = TableContainer;

var PaginationContainer = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  width: 100%;\n  .pagination-controls {\n    margin-top: 20px;\n    margin-left: auto;\n  }\n"])));

exports.PaginationContainer = PaginationContainer;