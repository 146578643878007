import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'
import { theme } from 'unioneofferte-core/lib/utils/theme'
import Loading from 'unioneofferte-core/lib/components/Loading'
import { Devtools } from '@stackhouseos/flow-client'

import 'moment'
import 'moment/locale/it'
import 'antd/dist/antd.css'

import App from './App'
import * as serviceWorker from './serviceWorker'
import { store, persistor } from './redux/store'

console.log('>>>>>>env---->>', process.env.REACT_APP_ENV)

if (process.env.REACT_APP_ENV === 'development')
  Devtools({ port: 8774 /* host: '192.168.1.234' */ })

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <App />
        </PersistGate>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
