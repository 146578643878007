import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useParams } from '@reach/router'
import Loading from 'unioneofferte-core/lib/components/Loading'
import './saga'

const PopulatedData = React.memo(({ flowName, reducer, path, collection }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const { id } = params

  useEffect(() => {
    dispatch({
      type: 'POPULATED_DATA',
      payload: { id, reducer, flowName, path, collection }
    })
  }, [dispatch, flowName, id, reducer, path, collection])

  return <Loading />
})

export default PopulatedData
