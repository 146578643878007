import { SET_PAGE, SET_PAGINATION, SET_PAGINATION_PROPS, RESET_PAGINATION } from './actions'
import { toPairs, cloneDeep, set, get } from 'lodash'

const initialState = {
  paginationData: {
    lastPage: null,
    pagination: {
      pageSizeOptions: [12, 24, 48, 54],
      pageSize: 12,
      current: 1,
      total: 0
    }
  }
}

export function withPaginationReducer(wrappedReducer, wrappedReducerName, wrappedReducerInitState) {
  return function getHigherOrderReducer(state = { ...initialState, ...wrappedReducerInitState }, action) {
    if (get(action, 'meta.reducer') !== wrappedReducerName) {
      return wrappedReducer(state, action)
    }

    switch (action.type) {
      case `${wrappedReducerName}/${SET_PAGE}`:
        return {
          ...state,
          paginationData: {
            lastPage: null,
            pagination: {
              ...state.paginationData.pagination,
              current: Math.max(1, action.payload)
            }
          }
        }
      case `${wrappedReducerName}/${SET_PAGINATION}`:
        return {
          ...state,
          paginationData: {
            ...state.paginationData,
            pagination: action.payload
          }
        }
      case `${wrappedReducerName}/${SET_PAGINATION_PROPS}`: {
        const props = toPairs(action.payload)
        const newPaginationData = cloneDeep(state.paginationData)
        for (const [key, value] of props) {
          set(newPaginationData, key, value)
        }
        return {
          ...state,
          paginationData: newPaginationData
        }
      }
      case `${wrappedReducerName}/${RESET_PAGINATION}`: {
        return {...initialState}
      }
      default:
        return wrappedReducer(state, action)
    }
  }
}
