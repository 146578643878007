import React from 'react'

import { Image } from 'antd'

const prefix = process.env.REACT_APP_S3_URL

const PreviewImage = ({ value, imageFormat }) => {
  return (
    <Image
      height={151}
      src={`${prefix}/campaigns/${value}/${imageFormat === 'jpeg' ? 'background' : 'logo'
        }.${imageFormat}`}
    >
      <Image
        preview={false}
        src={`${prefix}/campaigns/${value}/${imageFormat === 'jpeg' ? 'background' : 'logo'
          }.${imageFormat}`}
        width={200}
      />
    </Image>
  )
}
export default PreviewImage
