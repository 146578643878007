import { all, put, call, takeLatest } from 'redux-saga/effects'
import { mongo } from '../../utils/api'
import { actionUpdate } from '@stackhouseos/box-core'
import BSON from 'bson'
import { store } from '../../redux/store'

function* populatedData({ payload }) {
  const { flowName, reducer, collection } = payload || {}

  if (payload.id === 'create') {
    yield put({ type: 'flow/next', payload: { flowName, reducer } })
    return
  }

  try {
    const coll = mongo().collection(collection || reducer)
    const result = yield call([coll, 'findOne'], {
      _id: new BSON.ObjectId(payload.id.toString())
    })

    // TODO usare path e non data fisso
    yield put(actionUpdate(reducer, 'data', { ...result, id: payload.id }))

  } catch (error) {
    yield put(actionUpdate(reducer, 'error', error.message))
  } finally {
    yield put({ type: 'flow/next', payload: { flowName, reducer } })
  }
}

export default function* appSaga() {
  yield all([yield takeLatest('POPULATED_DATA', populatedData)])
}

store.injectSaga('flow-action-populatedData', appSaga)
