"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LogoFullContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject;

var LogoFullContainer = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  align-items: center;\n\n  @media only screen and (max-width: 600px) {\n  img {\n    padding-left:30px;\n    padding-right:30px;\n      }\n    }\n  }\n  img {\n    height: 25px;\n  }\n\n  h1 {\n    font-size: 36px;\n    font-family: 'serif';\n  }\n"])));

exports.LogoFullContainer = LogoFullContainer;