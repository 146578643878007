import { createSelector } from 'reselect'
import _prop from 'lodash/fp/prop'
import _get from 'lodash/get'

const selectCampaignState = _prop('campaigns')
const selectItems = createSelector(selectCampaignState, _prop('items'))
const selectData = createSelector(selectCampaignState, _prop('data'))

export const getCampaigns = createSelector(selectItems, (items) => items)

export const getCampaignData = createSelector(selectData, (data) => data)

export const getCampaignSlug = createSelector(selectData, (data) => data.slug)

export const getCampaignEmail = createSelector(
  selectData,
  (data) => data.emails
)
