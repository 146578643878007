import React, { useMemo } from 'react'
import Flow from '@stackhouseos/flow-client'
import { keyBy } from 'lodash'

const context = require.context('../../', true, /[\s\S]*?.flow.json$/)
const flowers = context.keys().map(filename => context(filename)).filter(e => e.type === 'flow')

const flow = keyBy(flowers, 'name')

export default React.memo(function UseFlow({ source, flowName, subReducerName, subFlowName, extraParams, startId }) {

  const extraContext = useMemo(() => ({
    subflow: (extraParams || []).reduce((acc, inc) => ({ ...acc, [inc.name]: inc.value }), {})
  }), [extraParams])

  return flow[source] ? (
    <Flow
      destroyOnUnmount
      parentFlow={flowName}
      elements={flow[source || subFlowName].elements}
      name={subFlowName}
      extraContext={extraContext}
      startId={startId}
      reducerName={subReducerName} // non mettere punti nel nome
    />
  ) : 'NO_FLOW'
})