"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Title = exports.BackgroundContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _theme = require("unioneofferte-core/lib/utils/theme");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

var BackgroundContainer = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  position: relative;\n  height: 60vh;\n  min-height: 400px;\n  overflow: hidden;\n  background: url(", ");\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n\n  &:after {\n    content: '';\n    background-color: rgba(0, 0, 0, 0.4);\n    position: absolute;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    top: 0;\n    z-index: 1;\n  }\n\n  .triangle {\n    position: absolute;\n    bottom: 0;\n    display: inline-block;\n    width: 0;\n    z-index: 2;\n    height: 0;\n    border-style: solid;\n  }\n\n  .left {\n    left: 0;\n    border-width: 15vh 0 0 50vw;\n    border-color: transparent transparent transparent white;\n    ", "\n  }\n\n  .right {\n    right: 0;\n    border-width: 0 0 15vh 50vw;\n    border-color: transparent transparent white transparent;\n    ", "\n  }\n"])), function (_ref) {
  var background = _ref.background;
  return background;
}, _theme.media.maxWidth('sm')(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n      border-width: 7vh 0 0 50vw;\n    "]))), _theme.media.maxWidth('sm')(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n      border-width: 0 0 7vh 50vw;\n    "]))));

exports.BackgroundContainer = BackgroundContainer;

var Title = _styledComponents["default"].div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n  position: absolute;\n  width: 100%;\n  bottom: 80px;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  z-index: 2;\n\n  .title {\n    font-size: 5vw;\n    ", "\n  }\n\n  > Button {\n    margin-top: 10px;\n  }\n"])), _theme.media.maxWidth('md')(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2["default"])(["\n      font-size: 8vw;\n  "]))));

exports.Title = Title;