import { all } from 'redux-saga/effects'
import auth from '../containers/Auth/saga'
import { flowSaga } from '@stackhouseos/flow-client'

const context = require.context('../', true, /containers\/[\s\S]*?\/saga.js$/)
const sagas = context.keys().map(filename => context(filename).default)

export default function* root() {
  yield all(
    [
      auth(),
      ...sagas.map(saga => saga()),
      flowSaga(),
    ]
  )
}