import { Layout, Menu } from 'antd'
import React, { useMemo } from 'react'
import { Link, Redirect, Router, useLocation } from '@reach/router'
import { PageContent } from '../Page/styled'
import Breadcrumbs from '../Breadcrumbs'
import { findIndex } from 'lodash'

const { Header, Content, Footer } = Layout

const RouteWrapper = ({ component }) => {
  return <PageContent>{component}</PageContent>
}

const Dashboard = ({ children, title, showLogout, pathLogout, firstRoute }) => {
  const location = useLocation()
  const currentMenu = location.pathname.split('/')

  const routes = useMemo(() => {
    return {
      menu: React.Children.map(children, (child) => ({
        title: child.props.field.title,
        path: child.props.field.path
      })),
      pages: React.Children.map(children, (child) => (
        <RouteWrapper
          key={child.key}
          default={child.props.field.path === 'default'}
          path={child.props.field.path}
          component={child}
        />
      ))
    }
  }, [children])

  const menuKey = findIndex(
    routes.menu,
    ({ path }) =>
      path.replace('/*', '') === currentMenu[1] ||
      path.replace('/', '') === currentMenu[1]
  ).toString()

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <div className='logo'>{title}</div>
        <Menu theme='dark' mode='horizontal' defaultSelectedKeys={[menuKey]}>
          {routes.menu.map((m, i) => (
            <Menu.Item key={i.toString()}>
              <Link to={m.path.replace('default', '').replace('/*', '')}>
                {m.title}
              </Link>
            </Menu.Item>
          ))}
          {showLogout && pathLogout && (
            <Menu.Item key={'LOGOUT'}>
              <Link to={pathLogout}>Logout</Link>
            </Menu.Item>
          )}
        </Menu>
      </Header>
      <Content
        className='site-layout'
        style={{ padding: '0 50px', marginTop: 64, height: '100%' }}
      >
        <Breadcrumbs />
        <div
          className='site-layout-background'
          style={{ padding: 24, minHeight: 380 }}
        >
          <Router>
            {routes.pages}
            {firstRoute && <Redirect from='/' to={firstRoute} />}
          </Router>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Stackhouse App</Footer>
    </Layout>
  )
}

export default React.memo(Dashboard)
